<template>
  <div></div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { iosview } from "@course-portal/iosview.js";

export default {
  name: "AppModeListeners",
  data() {
    return {
      iosview: iosview,
      redirectLink: null,
      launchRedirect: false,
      notificationClickedEventTarget: new EventTarget()
    };
  },
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
    }),
    ...mapState("pwa", {
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("courses", {
      courses: (state) => state.courses,
    }),
    salesPageUrl() {
      switch(window.App.appEnvironment) {
        case 'production':
          return 'https://secure.iphonephotographyschool.com'
        case 'testing':
          return 'http://sales.ips.test'
        default:
          return `https://${window.App.appEnvironment}-secure.iphonephotographyschool.com`
      }
    }
  },
  methods: {
    // Helper Methods
    addIosUrlsToLockedProducts(eventDetail) {
      const products = JSON.parse(eventDetail);

      products.forEach((product) => {
        if (!product.productIdentifier.includes('_lifetime_updates')) {
          this.processProduct(product, products);
        }
      });

      this.$store.dispatch('pwa/setIsIosUrlParams');
    },
    processProduct(product, products) {
      const matchingCourse = this.findMatchingCourse(product.productIdentifier);

      if (!matchingCourse) return;

      const { oldPrice, newPrice, luPrice } = this.calculatePrices(product, products, matchingCourse);
      const iosLockedParams = this.generateIosLockedParams(product.priceLocale, oldPrice, newPrice, luPrice);

      this.$store.commit('courses/SET_IOS_LOCKED_PARAMS', {
        appProductId: product.productIdentifier,
        iosLockedParams
      });
    },
    findMatchingCourse(productIdentifier) {
      const matchingCourse = this.courses.find(course => course.app_product_id === productIdentifier);

      if (!matchingCourse) {
        console.error(`Course not found for product: ${productIdentifier}`);
      }

      return matchingCourse;
    },
    calculatePrices(product, products, matchingCourse) {
      const originalPriceMultiplier = matchingCourse.original_price_coefficient ?? 5;
      const oldPrice = Math.ceil((product.price * originalPriceMultiplier) / 5) * 5;
      const newPrice = product.price;

      // Remove _discounted or _discount from the end of productIdentifier if it's launch.
      const baseIdentifier = product.productIdentifier.replace(/(_discount(ed)?)$/, '');
      const lifetimeUpdateKeyword = "lifetime_updates";

      const matchingLifetimeUpdatesProduct = products.find(p => {
        const isBaseIdentifierIncluded = p.productIdentifier.includes(baseIdentifier);
        const isLifetimeUpdateIncluded = p.productIdentifier.includes(lifetimeUpdateKeyword);
        return isBaseIdentifierIncluded && isLifetimeUpdateIncluded;
      });

      const luPrice = matchingLifetimeUpdatesProduct?.price ?? null;

      return { oldPrice, newPrice, luPrice };
    },
    generateIosLockedParams(priceLocale, oldPrice, newPrice, luPrice) {
      const iosSalesUrlParameters = 'iosview&iospwa';

      return `&${iosSalesUrlParameters}` +
             (luPrice ? `&luPrice=${priceLocale}${luPrice}` : '') +
             `&oldPrice=${priceLocale}${oldPrice}&newPrice=${priceLocale}${newPrice}`;
    },
    parseDetail(detail) {
      try {
        return JSON.parse(detail);
      } catch (error) {
        console.error("Error parsing detail:", error);
        return null;
      }
    },
    async restorePurchases(detailObject) {
      try {
        const response = await axios.post(
          `${this.salesPageUrl}/api/apple/restore-purchases`,
          {
            receipt_data: detailObject.receipt,
            country_code: detailObject.countryCode,
            currency: detailObject.currencyCode,
            uid: this.user.contact_id,
          },
          {
            headers: {
              "X-Requested-With": "mobile",
              Accept: "application/json",
            },
          }
        );
        this.$store.dispatch("pwa/setLoading", false);
        this.processRestoreResponse(response);
      } catch (error) {
        this.$store.dispatch("pwa/setLoading", false);
        alert(this.$t('restorePurchasesAlerts.error'));
        console.error("Restore:", error);
      }
    },
    processRestoreResponse(response) {
      const { products, products_with_different_contact } = response.data.data;

      if (products_with_different_contact.length > 0 && products.length === 0) {
        // Case 1: User has products with a different contact and no products in the current account
        alert(this.$t('restorePurchasesAlerts.otherAccount'));
      } else if (
        products_with_different_contact.length > 0 &&
        products.length > 0
      ) {
        // Case 2: User has products with a different contact and also products in the current account
        alert(this.$t('restorePurchasesAlerts.multipleAccounts'));
      } else {
        // Case 3: Finalize transactions and refresh courses
        this.iosview.restorePurchasesFinalized();
        alert(this.$t('restorePurchasesAlerts.success'));
        this.$store.dispatch("courses/setCourses");
      }
    },
    redirectToIosCourseUrl(url) {
      const courseRegex = /\/course\/(\d+)/;
      const match = url.pathname.match(courseRegex);

      if (match && match[1]) {
        const courseId = match[1];
        // Wait for courses to be loaded and ios_locked_params to be available
        this.waitForCoursesAndIosParams(courseId, (course) => {
          if (course) {
            // Replace /course/18 with /${course.product_slug}
            const updatedPathname = url.pathname.replace(courseRegex, `/${course.product_slug}`);
            // Construct the new URL
            const newUrl = `${this.salesPageUrl}${updatedPathname}${url.search}` + course.ios_locked_params;
            window.location.href = newUrl;
          } else {
            console.error('iOS URL for course not available.');
          }
          this.$store.dispatch("pwa/setLoading", false);
        });
      }
    },
    waitForCoursesAndIosParams(courseId, callback) {
      const checkInterval = 500;
      const maxAttempts = 20;
      let attempts = 0;

      const intervalId = setInterval(() => {
        // Check if courses are loaded
        if (this.courses?.length > 0) {
          // Find the course
          const course = this.courses.find(c => c.id.toString() === courseId);

          // If user has access to the course, redirect to the course page
          if (course && !course.locked) {
            window.location = "/course/" + courseId;
            clearInterval(intervalId);
          }
          // If course is locked and ios_locked_params are available, redirect to the sales page
          if (course && (course.ios_locked_params || attempts >= maxAttempts)) {
            clearInterval(intervalId);
            if (course.ios_locked_params) {
              callback(course);
            } else {
              console.error('iOS URL for course not available.');
              callback(null);
            }
          }
        }
        if (attempts >= maxAttempts) {
          clearInterval(intervalId);
          console.error('Courses not loaded or iOS URL for course not available.');
          callback(null);
        }
        attempts++;
      }, checkInterval);
    },

    // Listeners
    // In-App purchases Listeners
    handleIapProductsResult(event) {
      if (event && event.detail) {
        this.addIosUrlsToLockedProducts(event.detail);
      }
    },
    handleIapProductsResultFailure(event) {
      console.error("iap-products-result-failure", event.detail);
    },
    //  Restore In-App purchases Listeners
    handleIAPEmpty(event) {
      this.$store.dispatch("pwa/setLoading", false);
      alert(this.$t('restorePurchasesAlerts.noItems'));
    },
    handleIAPFailure(event) {
      this.$store.dispatch("pwa/setLoading", false);
      alert(this.$t('restorePurchasesAlerts.error'));
      if (event && event.detail) {
        console.log("handleIAPFailure: ", event.detail);
      }
    },
    async handleIAPResult(event) {
      if (event && event.detail) {
        const detailObject = this.parseDetail(event.detail);
        if (detailObject) {
          await this.restorePurchases(detailObject);
        } else {
          this.$store.dispatch("pwa/setLoading", false);
          console.error("Failed to parse event detail.");
        }
      }
    },
    // Push notification Listeners
    async handleNotificationClicked(event) {
      if (event && event.detail && event.detail !== "0") {
        const activity_id = event.detail;
        try {
          const response = await axios.post(
            `/api/v1/activity/${activity_id}/read`, {},
            {
              headers: {
                "X-Requested-Version": "1.4.0",
                "X-Requested-With": "mobile",
                Accept: "application/json",
              },
            }
          );
          this.iosview.setNotificationsBadge(response.data.unread_count);
        } catch (error) {
          console.error("Error with activity id", error);
        }
      } else {
        console.error("handleNotificationClicked - No event detail");
      }

      this.notificationClickedEventTarget.dispatchEvent(new CustomEvent('notificationClickedEvent'));
    },
    handleAllowNotificationsPopupResult(event) {
      if (event && event.detail) {
        console.log("allow-notifications-popup-result: ", event.detail);
      }
    },
    handlePushNotifications(event) {
      if (!this.$store) return;

      if (event && event.detail && typeof event.detail.link === 'string') {
        this.trackEvent('incoming_push_link', { link: event.detail.link });
        try {
          const url = new URL(event.detail.link);
          const path = url.pathname;
          const courseRegex = /\/course\/(\d+)/;
          const match = path.match(courseRegex);

          this.notificationClickedEventTarget.addEventListener('notificationClickedEvent', () => {
            if (match && match[1]) {
              this.$store.dispatch("pwa/setLoading", true);
              this.redirectToIosCourseUrl(url);
            } else {
              window.location.href = event.detail.link;
            }
          });
        } catch (error) {
          console.error('Error parsing URL from event detail:', error);
        }
      }
    },
    handleInitialNotificationStatus (event) {
      if (event.detail === 'false') {
        this.$store.dispatch('pwa/setAppNotificationsAllowed', false)
        this.$store.dispatch('pwa/removeDeviceToken')
        return
      }
      this.$store.dispatch('pwa/setAppNotificationsAllowed', true)
    },
  },
  mounted() {
    window.addEventListener(
      "iap-products-result",
      this.handleIapProductsResult
    );
    window.addEventListener(
      "iap-products-result-failure",
      this.handleIapProductsResultFailure
    );
    window.addEventListener(
      "iap-restore-purchase-empty",
      this.handleIAPEmpty
    );
    window.addEventListener(
      "iap-restore-purchase-failure",
      this.handleIAPFailure
    );
    window.addEventListener(
      "iap-restore-purchase-result",
      this.handleIAPResult
    );
    // Allow notifications popup
    window.addEventListener(
      "allow-notifications-popup-result",
      this.handleAllowNotificationsPopupResult
    );
    // Push notification with a notification ID from backend coming in from iOS
    // It's empty when there is no id for the notification
    window.addEventListener(
      "notification-clicked",
      this.handleNotificationClicked
    );
    // Push notification with a link coming in from iOS
    window.addEventListener(
      "push-notification",
      this.handlePushNotifications
    );
    window.addEventListener(
      "initial-notification-status-is-allowed",
      this.handleInitialNotificationStatus
    );
  },
  beforeUnmount() {
    window.removeEventListener(
      "iap-products-result-failure",
      this.handleIapProductsResultFailure
    );
    window.removeEventListener(
      "iap-restore-purchase-empty",
      this.handleIAPEmpty
    );
    window.removeEventListener(
      "iap-restore-purchase-failure",
      this.handleIAPFailure
    );
    window.removeEventListener(
      "iap-restore-purchase-result",
      this.handleIAPResult
    );
    window.removeEventListener(
      "iap-products-result",
      this.handleIapProductsResult
    );
    window.removeEventListener(
      "iap-products-result-failure",
      this.handleIapProductsResultFailure
    );
    window.removeEventListener(
      "notification-clicked",
      this.handleNotificationClicked
    );
    window.removeEventListener(
      "push-notification",
      this.handlePushNotifications
    );
    window.removeEventListener(
      "initial-notification-status-is-allowed",
      this.handleInitialNotificationStatus
    );
  },
};
</script>
